import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { ArticleEntity } from "../../entities/ArticleEntity"

type Breadcrumb = { label: string; url: string }

const getBreadcrumb = (breadcrumbs: Breadcrumb[], endpoint) => {
  const crumbs = [{ label: "Temple du Haïku", url: "" }, ...breadcrumbs]

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: crumbs.map(({ label, url }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: label,
      ...(index + 1 === crumbs.length ? {} : { item: `${endpoint}${url}` }),
    })),
  }
}

const getArticle = (article: ArticleEntity) => ({
  "@context": "https://schema.org",
  "@type": "Article",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": "https://google.com/article",
  },
  headline: article.seo__title,
  image: ["https://www.temple-du-haiku.fr" + article.image],
  author: {
    "@type": "Person",
    name: article.author.name,
  },
  publisher: {
    "@type": "Organization",
    name: "Temple du Haïku",
    logo: {
      "@type": "ImageObject",
      url: "https://www.temple-du-haiku.fr/manifest/192x192.png",
    },
  },
  datePublished: article.raw__created_at,
  dateCreated: article.raw__created_at,
  dateModified: article.raw__updated_at,
})

export const Seo: React.FC<{
  title?: string
  description?: string
  ads?: boolean
  image?: string
  article?: ArticleEntity
  breadcrumbs?: Array<Breadcrumb>
  index?: boolean
}> = ({
  title,
  description,
  image,
  article,
  breadcrumbs,
  index = true,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    favicon,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    favicon: favicon,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      htmlAttributes={{
        lang: "fr",
      }}
    >
      <link rel="icon" href={seo.favicon} />

      <meta name="description" content={seo.description} />

      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {!article && <meta property="og:type" content="website" />}

      {article && <meta property="og:type" content="article" />}

      {article && (
        <meta
          property="article:published_time"
          content={article.raw__created_at}
        />
      )}
      {article && (
        <meta
          property="article:modified_time"
          content={article.raw__updated_at}
        />
      )}
      {article && (
        <meta property="article:author" content={article.author.name} />
      )}
      {article && <meta property="author" content={article.author.name} />}
      {article && (
        <meta property="article:section" content={article.categories[0].name} />
      )}
      {article && <meta property="article:tag" content={"haïku"} />}
      {article && (
        <meta name="twitter:label1" content="Temps de lecture"></meta>
      )}
      {article && (
        <meta
          name="twitter:data1"
          content={`${article.reading} minutes`}
        ></meta>
      )}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {!index && <meta name="robots" content="noindex" />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@temple_du_haiku" />

      {twitterUsername && (
        <meta name="twitter:creator" content={`@${twitterUsername}`} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.image && <meta name="twitter:image:src" content={seo.image} />}

      {breadcrumbs && (
        <script type="application/ld+json">
          {JSON.stringify(getBreadcrumb(breadcrumbs, siteUrl))}
        </script>
      )}

      {article && (
        <script type="application/ld+json">
          {JSON.stringify(getArticle(article))}
        </script>
      )}

      {/* <script type="text/javascript">
        {`window.axeptioSettings = {
            clientId: "620f93de036aff029f316803",
            cookiesVersion: "temple-du-haiku-fr",
          };
           
          (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
          })(document, "script");`}
      </script> */}

      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1533832539203264"
      ></script>
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        favicon
      }
    }
  }
`
